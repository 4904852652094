import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { isMobile } from "react-device-detect";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { makeUrl, makeNews, makeArticles, removePreloader, makeVirtualTourSlides } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share, Weather } from "../../widgets";
import MapComponent from "../../components/Map";
import { PureCarousel } from "../../components/PureReactCarousel";
import Banner from "../../components/Banner";
import Contacts from "../../components/Contacts";
import EmptyData from "../../components/EmptyData";
import { MetaImage, MetaPublicAccess, MetaTelephone, MetaName, MetaTouristType, MetaOpeningHours, MetaAddress, MetaGeo } from "../../components/Meta";
import { CITY_OBJECT_MAX_UPDATE, CITY_OBJECT } from "../../queries/queries.graphql";

export const query = graphql`
	query cityObjectsDataQuery($slug: String!) {
		hasura {
			...CityObject
		}
	}
`;

export default function CityObjectsPage({ location, data, pageContext }) {
	const path = last(compact(location.pathname.split("/")));

	const [cityObjects, setCityObjects] = useState(get(data, "hasura.city_objects[0]", {}));

	const maxUpdated = new Date(get(data, "hasura.city_objects_aggregate.aggregate.max.updated_at", new Date()));

	const cityObjectsOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(CITY_OBJECT_MAX_UPDATE, cityObjectsOptions);
	const [loadCityObject, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(CITY_OBJECT, cityObjectsOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "city_objects_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadCityObject();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load CityObjectMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setCityObjects(get(fetchData, "city_objects[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const {
		main_image,
		title_full,
		telephone,
		main_image_preview,
		page_title_short,
		openning_hours,
		address,
		page_title_full,
		location_object,
	} = cityObjects;

	const url = makeUrl.cityObject(cityObjects);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const content_blocks = get(cityObjects, "content_blocks", []);
	const news = makeNews(get(cityObjects, "city_objects_news", []).map(item => item.news));
	const mediaArticles = makeArticles(get(cityObjects, "city_objects_media_articles", []).map(item => item.media_article));
	const bannerSlides = [
		{
			title: get(cityObjects, "page_title_full", ""),
			publicURL: get(cityObjects, "main_image.src"),
			mainImageMobile: get(cityObjects, "main_image_mobile.src", ""),
		},
	];
	const coordinates = get(
		get(cityObjects, "location_object.features", []).filter(
			feature => get(feature, "geometry.type", "") === "Point",
		),
		"[0].geometry.coordinates",
		[],
	);
	const weather = get(cityObjects, "city.weather", {});
	const virtualTour = makeVirtualTourSlides(get(cityObjects, "city_objects_virtual_tours", []));

	return cityObjects ? (
		<Pages entity={cityObjects} url={url}>
			<div itemScope itemType="https://schema.org/TouristAttraction">
				<MetaPublicAccess content="true" />
				<MetaGeo content={coordinates} />
				<MetaTouristType content="Культурный туризм, Городской туризм" />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				<ContentBlock key={"banner"}>
					<Banner slides={bannerSlides}>
						{isMobile && <Weather weather={weather} />}
					</Banner>
				</ContentBlock>
				<ContentBlock key={"breadcrumbs"}>
					<div className={"container d-flex justify-content-between"}>
						<Breadcrumbs currentLabel={title_full} pageContext={pageContext} />
						{!isMobile && <Weather weather={weather} />}
					</div>
				</ContentBlock>
				<ContentBlock
					key={"media-content"}
					mount={content_blocks && !!content_blocks.length}
				>
					<div className={"container"}>
						<div className={"h1 text-page_title"}>
							<h1 itemProp="name">{page_title_full}</h1>
						</div>
						<Contents items={content_blocks} />
					</div>
				</ContentBlock>
				<ContentBlock key={"news"} mount={news && !!news.length}>
					<div className={"container"}>
						<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
							<PureCarousel
								type={"SlideNews"}
								items={news}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 950,
											slidesPerView: 3,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"articles"} mount={mediaArticles && !!mediaArticles.length}>
					<div className={"container"}>
						<TextBlock title={"Статьи"}>
							<PureCarousel
								type={"SlideNews"}
								items={mediaArticles}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 5.5,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 3,
										},
										{
											width: 1136,
											slidesPerView: 4,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
					<div className={"container"} >
						<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
							<PureCarousel
								type={"SlideLinks"}
								items={virtualTour}
								slideRatio={{
									naturalSlideWidth: 2,
									naturalSlideHeight: 1,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 1,
									naturalSlideHeight: 0.8,
								}}
								params={{
									slidesPerView: 2,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 1136,
											slidesPerView: 2,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock mount={cityObjects && address || openning_hours || telephone} key={"contacts"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Контакты"} className={"pb-2"}/>
							</div>
						</div>
						<Contacts address={address} hours={openning_hours} telephone={telephone}/>
					</div>
				</ContentBlock>
				<ContentBlock mount={cityObjects && location_object} key={"map"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Расположение"} />
							</div>
						</div>
					</div>
					<MapComponent items={[cityObjects]} showPolygon />
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

CityObjectsPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

CityObjectsPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
